import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import apiCall from '../utilities/auth/apiCall';
import { register } from '../utilities/auth/authApi';
import { isAuthed, saveToken } from "../utilities/auth/tokenHelpers";
import FormErrorMessage from '../utilities/formErrorMessage';


function Register() {

  // Define variables
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  // Page load actions
  useEffect(() => {

    // Redirect if already logged in
    const isLoggedIn = isAuthed();
    if( isLoggedIn ) {
      navigate("/dashboard");
    }

  }, []);


  // Define functions
  function callRegister(event) {
    event.preventDefault();

    const payload = {
      user: {
        email: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }

    apiCall(register, payload).then((response) => {
      if( response.success ) {
        saveToken(response.data.jwt);
        localStorage.setItem("isNewAccount", true);
        setTimeout(() => {
          navigate("/dashboard");
        }, 300);
      } else {
        console.log('error -> ', response.error);
        setErrorMessage(response.error);
      }
    });
  }


  return(
    <div class='flex-column' style={{height: '100vh'}}>

      <header class='flex-row'>
        <div class='logo-square'></div>
        <h1>Dia Daily Standup</h1>
      </header>

      <section class='body-content'>

        <h1>Register</h1>

        <FormErrorMessage message={errorMessage} />

        <form class='form' onSubmit={(event) => callRegister(event)}>

          <div class='input-label'>
            <input
              type='text'
              onChange={(event) => setFirstName(event.target.value)}
              value={firstName}
              placeholder='First name'
              required
            />
          </div>

          <div class='input-label'>
            <input
              type='text'
              onChange={(event) => setLastName(event.target.value)}
              value={lastName}
              placeholder='Last name'
              required
            />
          </div>

          <div class='input-label'>
            <input
              type='email'
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              placeholder='Email address'
              required
            />
          </div>

          <div class='input-label'>
            <input
              type='password'
              onChange={(event) => setPassword(event.target.value)}
              value={password}
              placeholder='Password'
              minLength={8}
              required
            />
          </div>

          <div class='input-label'>
            <button type='submit' class='button'>Register</button>
          </div>

        </form>

        <br /><br /><br />
        <Link to='/login' class='sub-text'>Already have an account? Login</Link>

      </section>
    </div>
  );
}

export default Register;